<template>
  <div>
    <h3 class="question-description">
      <div v-html="$options.filters.formatBrTagText(question.description)"></div>
    </h3>
    <h4 class="question-script" v-if="question.script">
      <div v-html="$options.filters.formatBrTagText(question.script)"></div>
    </h4>
    <div class="question-script" v-if="question.image">
      <el-image :src="question.image" fit="fill"></el-image>
    </div>
    <div v-if="question.video" class="mb-3 overflow-hidden">
      <video ref="video" controls preload="metadata" class="w-video">
        <source :src="appendSuffixUrl(question.video)" type="video/mp4" />
      </video>
    </div>
    <div v-if="question.audio" class="mb-3">
      <audio ref="audio" controls preload="metadata">
        <!-- <source :src="question.audio" type="audio/ogg" /> -->
        <source :src="question.audio" type="audio/mpeg" />
      </audio>
    </div>
  </div>
</template>

<script>
export default  {
  name: "homework-container",
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    question: {
      handler() {
        if (this.question.audio) {
          this.$refs["audio"]?.load();
        }
        if (this.question.video) {
          this.$refs["video"]?.load();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    appendSuffixUrl(url) {
      return url + "#t=0.1";
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .w-video {
    width: 100%;
    object-fit: contain;
    height: 240px;
  }
}
@media only screen and (min-width: 768px) {
  .w-video {
    width: 375px;
    object-fit: contain;
    height: 240px;
  }
}
</style>